import { Box, Button, Tab } from '@mui/material';
import React, {
  FunctionComponent,
  memo,
  useCallback,
  useMemo,
  useState,
} from 'react';

import { map, keys, find, reduce, mapValues, orderBy, get } from 'lodash';

import { Close } from '@mui/icons-material';

import { TabContext, TabList, TabPanel } from '@mui/lab';

import Flex from '../../shared/Flex';

import SkeletonTable from '../../shared/Table/SkeletonTable';
import { ModelInstance, RowIdentifier } from '../../../types/models';

import RemoveInputDialog from './RemoveInputDialog';

import InputTabPanel from './InputTabPanel';

import { IInputsResponse, IModifiedRowData, ISelectedInputs } from './index';

interface Props {
  selectedInputs: ISelectedInputs;
  selectedInputTab: string;
  handleChangeTab: (selectedTab: string) => void;
  onClose?: (selectedTab: string) => void;
  selectedTabsData: IInputsResponse[];
  selectedOriginalTabsData: IInputsResponse[];
  getModifiedRowData: (data: { Input: string; Data: any[] }) => void;
  resetRowData: (inputName: string) => void;
  loading: boolean;
  modelInstance?: ModelInstance;
  modifiedRowData: IModifiedRowData[];
  invalidInputs: any[];
}

const InputTabs: FunctionComponent<Props> = ({
  selectedInputs,
  selectedInputTab,
  handleChangeTab,
  onClose,
  selectedTabsData,
  selectedOriginalTabsData,
  getModifiedRowData,
  resetRowData,
  loading,
  modelInstance,
  modifiedRowData,
  invalidInputs,
}) => {
  const [closeTab, setCloseTab] = useState<string>('');

  const confirmRemoveTab = useCallback(() => {
    onClose && onClose(closeTab);
    setCloseTab('');
  }, [closeTab]);

  const inputsList = useMemo(
    () => orderBy(selectedInputs, ['addedAt'], ['desc']),
    [selectedInputs]
  );

  const inputTabsList = useMemo(() => {
    const inputTabs = reduce(
      inputsList,
      (acc: Record<string, RowIdentifier>, input) => {
        acc[input.Name] = input;
        return acc;
      },
      {}
    );

    return mapValues(inputTabs, (input) => (
      <InputTabPanel
        inputName={input.Name}
        selectedInput={find(selectedTabsData, (i) => i.Name === input.Name)}
        getModifiedRowData={getModifiedRowData}
        resetRowData={resetRowData}
        modelInstance={modelInstance}
        modifiedRowData={modifiedRowData}
        invalidInputs={invalidInputs}
      />
    ));
  }, [
    inputsList,
    selectedTabsData,
    selectedOriginalTabsData,
    loading,
    modifiedRowData,
    invalidInputs,
  ]);

  return (
    <Box my={2}>
      <RemoveInputDialog
        open={Boolean(closeTab)}
        onClose={() => setCloseTab('')}
        onRemove={confirmRemoveTab}
        hasBeenModified={get(
          find(modifiedRowData, {
            Input: closeTab,
          }) as IModifiedRowData,
          'isDirty',
          false
        )}
      />
      <TabContext value={selectedInputTab}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList
            onChange={(e, newTab) => handleChangeTab(newTab)}
            aria-label="edit assumptions input tabs"
            variant="scrollable"
            scrollButtons="auto"
          >
            {map(keys(inputTabsList), (input) => {
              return (
                <Tab
                  sx={{ textTransform: 'none' }}
                  label={
                    <Flex alignItems="center">
                      {get(
                        find(modifiedRowData, {
                          Input: input,
                        }) as IModifiedRowData,
                        'isDirty',
                        false
                      ) && (
                        <Box
                          sx={{
                            height: 6,
                            width: 6,
                            backgroundColor: (t) => t.palette.secondary.main,
                            borderRadius: '50%',
                            display: 'inline-block',
                          }}
                        />
                      )}
                      &nbsp;&nbsp;
                      {input}
                      {onClose && (
                        <Button
                          component="span"
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            setCloseTab(input);
                          }}
                        >
                          <Close fontSize="small" />
                        </Button>
                      )}
                    </Flex>
                  }
                  key={input}
                  value={input}
                />
              );
            })}
          </TabList>
        </Box>
        {map(keys(inputTabsList), (input) => (
          <TabPanel value={input} key={input}>
            {loading ? <SkeletonTable /> : inputTabsList[input]}
          </TabPanel>
        ))}
      </TabContext>
    </Box>
  );
};

const InputTabsMemoized = memo(InputTabs);

export default InputTabsMemoized;
